<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="query.askStatus"
              :options="codes.askStatus"
              class="el-def"
              placeholder="답변여부"
              :reduce="option => option.code"
              @input="refetchData()"
            />
            <b-form-datepicker
              v-model="query.createDateFr"
              class="el-def"
              placeholder="등록일(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.createDateTo"
              class="el-def"
              placeholder="등록일(To)"
            />
            <b-form-input
              v-model="query.filterStr"
              class="el-def"
              placeholder="검색어"
              maxlength="30"
              @keyup.enter="refetchData()"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              Call 접수 건수 {{ callCount }}건 / 처리 건수 {{ answerCount }}건
            </h5>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-dark"
              class="btn-search"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refCallTable"
        primary-key="id"
        :items="fetchMemberCalls"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        class="position-relative"
      >
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
          </span>
        </template>
        <template #cell(status)="data">
          <span :class="{'text-primary': data.value === '답변대기', 'text-danger': data.value === '답변완료'}">
            {{ data.value }}
          </span>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { ref, onMounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"

export default {
  components: {
    vSelect,
  },

  setup() {
    onMounted(() => {
      fetchCodes()
    })

    const toast = useToast()

    const query = ref({
      askStatus: null,
      createDateFr: null, // dayjs().add(-3, 'month').format('YYYY-MM-DD'),
      createDateTo: null, // dayjs().format('YYYY-MM-DD'),
      filterStr: null,
    })

    const codes = ref({
      askStatus: [],
    })

    const list = ref([])
    const refCallTable = ref(null)
    const sortBy = ref('id')
    const isSortDesc = ref(true)
    const totalRecords = ref(0)
    const callCount = ref(0)
    const answerCount = ref(0)
    const tableColumns = [
      {
        key: 'id', label: 'Id', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'hcpLicenseNumber', label: '의사면허번호', sortable: true, thStyle: { width: '10%' },
      },
      {
        key: 'userName', label: '의사명', sortable: true, thStyle: { width: '10%' },
      },
      {
        key: 'content', label: '문의 내용', sortable: true,
      },
      {
        key: 'answer', label: '답변 내용', sortable: true,
      },
      {
        key: 'createDate', label: '문의 등록일시', sortable: true, thStyle: { width: '12%' },
      },
      {
        key: 'status', label: '처리여부', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'answerUserName', label: '담당자', sortable: true, thStyle: { width: '7%' },
      },
    ]

    const refetchData = () => {
      refCallTable.value.refresh()
    }

    const fetchCodes = () => {
      axios.get('/fa/ask-history/codes')
        .then(rs => {
          const { askStatus } = rs.data

          codes.value.askStatus = askStatus
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '코드 데이터를 불러오는데 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchMemberCalls = (ctx, callback) => {
      axios.post('/fa/ask-history/call/list', {
        search: {
          askStatus: query.value.askStatus,
          createDateFr: query.value.createDateFr ? `${query.value.createDateFr}T00:00:00` : null,
          createDateTo: query.value.createDateTo ? `${query.value.createDateTo}T23:59:59` : null,
          filterStr: query.value.filterStr,
        },
        sort: {
          predicate: sortBy.value,
          reverse: isSortDesc.value,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data

          callback(items)

          list.value = items
          callCount.value = totalRecord
          answerCount.value = items.filter(e => e.status !== null).length
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    // TODO
    // 내부망에서만 다운로드 시 예외처리 필요함
    const excelDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.columns = [
        { header: "의사면허번호", key: "hcpLicenseNumber", width: 15 },
        { header: "의사명", key: "userName", width: 10 },
        { header: "문의 내용", key: "content", width: 30 },
        { header: "답변 내용", key: "answer", width: 30 },
        { header: "문의 등록일시", key: "createDate", width: 20 },
        { header: "처리여부", key: "status", width: 12 },
        { header: "담당자", key: "answerUserName", width: 10 },
      ]

      list.value.map(e => worksheet.addRow({
        hcpLicenseNumber: e.hcpLicenseNumber,
        userName: e.userName,
        content: e.content,
        answer: e.answer,
        createDate: dayjs(e.createDate).format('YYYY-MM-DD HH:mm'),
        status: e.status,
        answerUserName: e.answerUserName,
      }))

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "Call문의내역_리스트.xlsx")
    }

    return {
      query,
      codes,
      tableColumns,
      refCallTable,
      sortBy,
      isSortDesc,
      totalRecords,
      callCount,
      answerCount,
      list,
      refetchData,
      fetchMemberCalls,
      excelDownload,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
